import React from "react";
import { Link } from "gatsby";
import { FormattedMessage } from "react-intl";
import styles from "../styles/Footer.module.css";
import Logo from "../assets/logo.png";
// import Linkedin from "../assets/linkedin.png";

const Footer = ({links = [], showLinkedInLogo = true, page = "infosite"}) => {
    const renderLink = (pathname, text, index) => {
        if(/https:\/\//.test(pathname)) {
            return <a key={index} href={pathname} target="_blank" rel="noreferrer"><FormattedMessage id={text} /></a>
        }

        return (
            <Link to={pathname} key={index}><FormattedMessage id={text} /></Link>
        );
    };

    return (
        <div className={`${styles.Footer} layout-footer`}>
            <div className={styles.FooterPlaceholder}>
                <nav className={styles.FooterNav}>
                    {links.map((item, index) => renderLink(item.path, item.key, index))}
                    {/* {
                        page === "infosite" ?
                        <Link to="/contact" className={styles.FooterNavContactLink}><FormattedMessage id="contact" /></Link> :
                        <a href="/customer-faq" target="_blank" className={styles.FooterNavContactLink}><FormattedMessage id="faqShortTitle" /></a>
                    }
                    {showLinkedInLogo && <a href="https://www.linkedin.com/company/tyviso/" target="_blank" rel="noreferrer" className={styles.FooterNavSocialLink}><img src={Linkedin} alt="LinkedIn" /></a>} */}
                </nav>
                <p>Have any questions? Feel free to <a href="mailto:hello@tyviso.com">contact us</a></p>
                <Link to="/" className={styles.FooterLogo}><img src={Logo} alt="Tyviso.com" /></Link>
                <p>{`©${new Date().getFullYear()} Tyviso • all rights reserved`}</p>
            </div>
        </div>
    );
};

export default Footer;